<template>
  <v-container> </v-container>
</template>

<script>
import VueJwtDecode from "vue-jwt-decode";
import { mapMutations } from "vuex";

export default {
  mounted() {
    var id_token = this.getParamValue("id_token");
    // var scope = this.getParamValue("scope");
    // var login_hint = this.getParamValue("login_hint");
    // var client_id = this.getParamValue("client_id");
    // var prompt = this.getParamValue("prompt");

    var googleJWT = VueJwtDecode.decode(id_token);

    this.toogleDialogLoading(true);
    var user = {
      nome: googleJWT.email,
      email: googleJWT.email,
      img: 'https://www.pngfind.com/pngs/m/110-1102775_download-empty-profile-hd-png-download.png'
    };
    this.$auth.externalLogin(user);
  },
  methods: {
    ...mapMutations(["toogleDialogLoading"]),
    getParamValue(paramname) {
      var url = window.location.href;
      var paramnamefull = paramname + "=";
      var ini = url.indexOf(paramnamefull);
      var firstpart = url.substring(ini + paramnamefull.length);
      var end = firstpart.indexOf("&");
      if (end < 0) end = paramnamefull.length;
      var paramvalue = firstpart.substring(0, end);
      return paramvalue;
    }
  }
};
</script>
